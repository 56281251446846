





























import { Component, Watch, Vue } from "vue-property-decorator";
import BasePage from "@/components/pages/BasePage.vue";
import BaseWizard from "@/components/wizard/BaseWizard.vue"

import { namespace } from 'vuex-class';

import WizardStepBalance from "@/components/wizard/balance/WizardStepBalance.vue";

const Products = namespace("Products");
const GasAccounts = namespace("GasAccounts");

@Component({
    components: {
        BasePage,
        BaseWizard,
        WizardStepBalance
    },
})
export default class Balance extends Vue {
    private isExactActive = false;
    private componentKey = 0;
    private transitionName = 'fade';
    private visible = true;

    @Watch('$route.path', { immediate: true, deep: true })
    onUrlChange(to: string, from: string) {
        if (to == null || to == undefined) {
            this.transitionName = 'fade';
            return;
        }
        const toDepth = to.split('/').length
        const fromDepth = from?.split('/').length
        this.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left';
    }

    @Products.Getter
    private getSelectedProduct!: string;

    @GasAccounts.Mutation
    private setMprnSelectionDisabled!: (disabled: boolean) => void;

    @GasAccounts.Mutation
    private setAgreementSelectionDisabled!: (disabled: boolean) => void;

    updated() {
        this.isExactActive = typeof this.$refs.rv === 'undefined';
    }

    mounted() {
        this.isExactActive = typeof this.$refs.rv === 'undefined';

        this.setMprnSelectionDisabled(true);
        this.setAgreementSelectionDisabled(true);
    }

    beforeDestroy(): void {
        this.setMprnSelectionDisabled(false);
        this.setAgreementSelectionDisabled(false);
    }


    forceRerender() {
        this.componentKey += 1
    }
}
