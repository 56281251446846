


























































































import WizardBaseStep from "@/components/wizard/WizardBaseStep.vue"
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { IAccountBalanceDetails } from "@/interfaces/accountBalanceDetails";
import { FileProcess } from "@/utilities/FileProcess";
import { GtagHelper } from '../../../utilities/GtagHelper';


import moment from 'moment';

const Auth = namespace("Auth");
const GasAccounts = namespace("GasAccounts");
const Balance = namespace("Balance");
const Products = namespace("Products");
const Invoices = namespace("Invoices");

@Component({
    components: {
        WizardBaseStep            
    },
    filters: {
        formatDate(value: any) {

            if (value === null || value === "") {
                return "";
            }

            return moment(value).format('DD/MM/YYYY');
        },

        setBadgeStatus(value: any) {

            if (value == "Credit") return "badge-credit";
            if (value == "Debit") return "badge-debit";
            return "";
        }
    }
})
export default class WizardStepBalance extends Vue {

    @Prop({ type: String, default: '' }) readonly id!: string;
    @Prop({ type: String, default: '' }) readonly WizardBaseStepTitle!: string;
    @Prop({ type: Boolean, default: false }) readonly visible!: boolean;

    private processing = false;
    private watchGasAccountSelection: any;
    private watchSelectedProduct: any;

    private AccountBalance = "";
    private OverdueBalance = "";
    private CreditOrDebit = "";

    private FileProcess = new FileProcess();

    private GtagHelper = new GtagHelper();

    public InvoiceFields = [
        {
            key: 'DocumentDate',
            label: 'Document date',
            formatter: this.formatDate
        },
        {
            key: 'SiteRef',
            label: 'Agreement'
        },
        {
            key: 'InvoiceNo',
            label: 'Document number'
        },
        {
            key: 'DownloadLink',
            label: 'Download'
        },
        {
            key: 'DueDate',
            label: 'Due date',
            formatter: this.formatDate
        },
        {
            key: 'DebitAmount',
            label: 'Debit (£)',
            formatter: this.formatCurrency
        },
        {
            key: 'CreditAmount',
            label: 'Credit (£)',
            formatter: this.formatCurrency
        },
        {
            key: 'RunningBalance',
            label: 'Balance (£)',
            formatter: this.formatCurrency
        }];

    @Auth.Getter
    private getUserId!: string;

    @GasAccounts.Getter
    private getSelectedAccount!: string;

    @Balance.Getter
    private getBalanceDetails!: IAccountBalanceDetails;

    @Balance.Action
    private submitGetBalanceDetails!: (params: { userId: string, accountNumber: string }) => Promise<void>;

    @Balance.Action
    private submitDownloadDDManadate!: (params: { userId: string, accountNumber: string }) => Promise<void>;

    @Balance.Action
    private submitDownloadBalanceXlsx!: (params: { userId: string, accountNumber: string }) => Promise<void>;

    @Balance.Action
    private submitDownloadBalancePDF!: (params: { userId: string, accountNumber: string }) => Promise<void>;

    @Balance.Getter
    private getDownloadBalanceFileData!: { data: Blob | null, fileName: string | null };

    @Invoices.Action
    private invoiceDownload!: (params: { accountNum: string, invoiceNo: string, userId: string }) => Promise<void>;

    @Invoices.Getter
    private getDownloadFileData!: { data: Blob | null, fileName: string | null };

    private async onClick(invoiceNo) {
        this.$refs[invoiceNo]!['$el'].style.display = 'flex';
        const accountNum = this.getSelectedAccount;
        const userId = this.getUserId;
        this.invoiceDownload({ accountNum, invoiceNo, userId }).then(() => {
            this.fileProcess();
            this.$refs[invoiceNo]!['$el'].style.display = 'none';
        }).catch(reason => {
            console.error(reason);
            this.$refs[invoiceNo]!['$el'].style.display = 'none';
        });
    }

    private fileProcess() {

        console.log("fileProcess");

        if (this.getDownloadFileData == null) {
            return null;
        }

        var fileName = this.getDownloadFileData.fileName;
        console.log(fileName);
        if (window.navigator && window.navigator.msSaveBlob) {
            window.navigator.msSaveBlob((this.getDownloadFileData.data) as Blob, fileName as string);
        } else {
            var fileURL = window.URL.createObjectURL((this.getDownloadFileData.data) as Blob);
            var fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.setAttribute('download', fileName as string);
            document.body.appendChild(fileLink);
            fileLink.click();
        }

        this.GtagHelper.Event("LumiDownloadInvoiceBalancePage", this.getUserId);
    }

    mounted() {

        if (this.getSelectedAccount != null) {
            this.GetBalanceDetails();
        } else {
            this.processing = true;
        }

        this.watchGasAccountSelection = this.$store.watch((state, getters) => getters["GasAccounts/getSelectedAccount"], (newValue, oldValue) => {
            if (newValue === null || newValue === oldValue) {
                return;
            }
            this.GetBalanceDetails();
        });

    }



    GetBalanceDetails(): void {
        this.processing = true;
        this.AccountBalance = "";
        this.OverdueBalance = "";
        this.CreditOrDebit = "";
        this.submitGetBalanceDetails({ userId: this.getUserId, accountNumber: this.getSelectedAccount }).then(() => {
            this.AccountBalance = this.getBalanceDetails.AccountBalance;
            this.OverdueBalance = this.getBalanceDetails.OverdueBalance;
            this.CreditOrDebit = this.getBalanceDetails.CreditOrDebit;
            this.processing = false;
        });
    }

    DownloadDDMandate(): void {
        this.submitDownloadDDManadate({ userId: this.getUserId, accountNumber: this.getSelectedAccount }).then(() => {
            this.FileProcess.downlaodFile(this.getDownloadBalanceFileData.data, this.getDownloadBalanceFileData.fileName);
        });
    }

    DownloadXlsx(): void {
        this.submitDownloadBalanceXlsx({ userId: this.getUserId, accountNumber: this.getSelectedAccount }).then(() => {
            let success = this.FileProcess.downlaodFile(this.getDownloadBalanceFileData.data, this.getDownloadBalanceFileData.fileName);
            if (success) {
                this.GtagHelper.Event("LumiDownloadBalanceXls", this.getUserId);
            }
        });
    }

    DownloadPDF(): void {
        this.submitDownloadBalancePDF({ userId: this.getUserId, accountNumber: this.getSelectedAccount }).then(() => {
            let success = this.FileProcess.downlaodFile(this.getDownloadBalanceFileData.data, this.getDownloadBalanceFileData.fileName);
            if (success) {
                this.GtagHelper.Event("LumiDownloadBalancePdf", this.getUserId);
            }
        });
    }

    beforeDestroy() {
        this.watchGasAccountSelection();
        this.watchSelectedProduct();
    }

    public invoiceUnavailable(invoiceDate: string): boolean {

        const result = moment(invoiceDate).isBefore('01 Jan 2014');

        return result;
    }

    public invalidInvoiceNo(invoiceNo: string): boolean {

        const result = (invoiceNo.substring(0, 3) == "INV" || invoiceNo.substring(0, 2) == "CR");

        return result;
    }

    private formatDate(value: string): string {
        if (value === null) {
            return "";
        }
        return moment(value).format('DD/MM/YYYY');
    }

    private formatCurrency(value: number): string {


        var formatter = new Intl.NumberFormat('en-GB', {
            style: 'currency',
            currency: 'GBP'
        });


        if (value == 0) {
            return "0.00";
        }
        else {
            return formatter.format(value).replace("£","").trim();
            //fix currency formatting and remove £
        }
    }

}
